import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import { useFormik } from 'formik';
import _ from 'lodash';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),

            form: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const account_id = ctx.apply('accountModel.getAccountId');
              const Yup = ctx.get('ValidateHandler.Yup');
              const form = useFormik({
                initialValues: {
                  id: account_id,
                  name: _.get(routeParams, 'account_profile.display_name', ''),
                  avatar_url: !_.get(routeParams, 'account_profile.avatar_url', '')
                    ? ''
                    : [
                        {
                          url: _.get(routeParams, 'account_profile.avatar_url', ''),
                          status: 'success',
                        },
                      ],
                },
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      display_name: values.name,
                      avatar_url: _.get(values, 'avatar_url.0.url'),
                    };

                    const client = await getClient();
                    await client.request(
                      gql`
                        mutation UpdateAccountProfile(
                          $where: b2b_account_profile_bool_exp!
                          $_set: b2b_account_profile_set_input = {}
                        ) {
                          update_b2b_account_profile(
                            where: $where
                            _set: $_set
                          ) {
                            returning {
                              id
                              avatar_url
                              display_name
                            }
                          }
                        }
                      `,
                      { where: { account_id: { _eq: account_id } }, _set: payload }
                    );
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid,

                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return ctx.get('authModel.isLoadingRedirectResult');
          }),
        },
      ],
    ],
  },
  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
