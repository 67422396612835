import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import Image from '@uz/unitz-components-web/Image';
import Button from '@uz/unitz-components-web/Button';
import InputText from '@uz/unitz-components-web/InputText';
import { FormItem } from 'formik-antd';
import { Upload, Progress } from 'antd';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import CustomInput from '@uz/unitz-components-web/CustomInput';

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="component">
      <FormItem
        name="name"
        label={
          <span className="text-sm font-semibold text-sub">
            {ctx.apply('i18n.t', 'ZoomToolAccount.Form.organization_name')}
          </span>
        }
        required
      >
        <CustomInput>
          <InputText name="name" size="medium" />
        </CustomInput>
      </FormItem>
      <FormItem
        name="id"
        label={
          <span className="text-sm font-semibold text-sub">
            {ctx.apply('i18n.t', 'ZoomToolAccount.Form.organization_id')}
          </span>
        }
        required
      >
        <CustomInput>
          <InputText name="id" size="medium" disabled />
        </CustomInput>
      </FormItem>
      <FormItem
        name="avatar_url"
        label={
          <span className="text-sm font-semibold text-sub">{ctx.apply('i18n.t', 'ZoomToolAccount.Form.logo')}</span>
        }
      >
        <CustomInput>
          <div className="relative">
            <CoverPhotoInput
              {...ctx.apply('form.getUploaderProps', { fieldName: 'avatar_url', multiple: false })}
              itemRender={() => null}
            >
              {!ctx.get('form.values.avatar_url.0') ? (
                <div className="flex flex-col items-center justify-center py-6">
                  <div className="mb-2 text-center ant-upload-text">
                    {gstyles.icons({ name: 'photo-library', size: 24, fill: gstyles.colors.sub })}
                  </div>
                  <Button style={{ borderColor: gstyles.colors.border }} name="ink">
                    <span>{ctx.apply('i18n.t', 'ZoomToolAccount.Form.add_logo')}</span>
                  </Button>
                  <div className="text-xs my-2 font-normal ant-upload-hint text-sub">
                    {ctx.apply('i18n.t', 'ZoomToolAccount.Form.add_logo')}
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <Image
                    alt="photo"
                    src={ctx.get('form.values.avatar_url.0.url')}
                    className="overflow-hidden rounded-lg"
                    width={364}
                    height={205}
                  />
                </div>
              )}
            </CoverPhotoInput>
            {ctx.get('form.values.avatar_url.0') && (
              <div className="absolute top-0 right-0">
                <Button type="link" name="ink" onClick={() => ctx.apply('form.setFieldValue', 'avatar_url', [])}>
                  <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                </Button>
              </div>
            )}
            {ctx.get('form.values.avatar_url.0.status') === 'uploading' && (
              <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                <Progress width={80} type="circle" percent={ctx.get('form.values.avatar_url.0.percent')} />
              </div>
            )}
          </div>
        </CustomInput>
      </FormItem>
    </DIV>
  );
};

export default Index;
