import React from 'react';
import createLayout from '@vl/mod-utils/createLayout';
import { Helmet } from 'react-helmet';
import GbContentfulProvider from '@uz/unitz-providers/GbContentfulProvider';
import { Layout as AntdLayout } from 'antd';
import ZoomToolOrganizationMenu from '@uz/unitz-tool-components/ZoomToolOrganizationMenu';
import LayoutNavMenu from '@uz/unitz-layout-web/LayoutNavMenu';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import gstyles from '@vl/gstyles';
const NavMenu = () => (
  <LayoutNavMenu.POS name="nav-menu">
    <ZoomToolOrganizationMenu />
  </LayoutNavMenu.POS>
);

export const Layout = createLayout(({ children, PageData }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="https://unitz-css.web.app/styles.css?v=2022120910" />
      </Helmet>
      <PageData>
        {(ctfData) => {
          return (
            <GbContentfulProvider data={ctfData}>
              <div>
                <div id="layoutcontent">{children}</div>
                <div className="h-full min-h-screen bg-background1 h-fill-screen">
                  <div className="app-header">
                    <Layout.RenderPOS name="app-header" />
                  </div>
                  <div className="w-full h-full min-h-screen h-fill-screen">
                    <AntdLayout className="py-0 h-full h-fill-screen min-h-screen">
                      <ResponsiveProps
                        lg={{ renderer: () => <div /> }}
                        xl={{
                          renderer: () => (
                            <AntdLayout.Sider
                              style={{ backgroundColor: gstyles.colors.white500, height: '100%' }}
                              width={264}
                              className="p-4"
                              collapsedWidth="0"
                              // breakpoint="lg"
                            >
                              <ZoomToolOrganizationMenu />
                            </AntdLayout.Sider>
                          ),
                        }}
                      >
                        {(resProps) => !!resProps && resProps.renderer()}
                      </ResponsiveProps>
                      <AntdLayout.Content className="app-body">
                        <div className="bg-transparent app-body">
                          <Layout.RenderPOS name="app-body" />
                        </div>
                      </AntdLayout.Content>
                    </AntdLayout>
                  </div>
                  <div className="app-footer">
                    <Layout.RenderPOS name="app-footer" />
                  </div>
                </div>
                <ResponsiveProps
                  lg={{
                    renderer: () => <NavMenu />,
                  }}
                  lxlg={{ renderer: () => <div /> }}
                >
                  {(resProps) => !!resProps && resProps.renderer()}
                </ResponsiveProps>
              </div>
            </GbContentfulProvider>
          );
        }}
      </PageData>
    </>
  );
});

export default Layout;
